@tailwind base;
/* @tailwind components; */
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');
*{
  box-sizing: border-box;
}

html{
  @apply pf-scroll-smooth pf-bg-white
}

body {
  margin: 0;
  font-family: 'Poppins', sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

a{
  @apply pf-text-primary pf-font-bold
}

.profileImg{
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.3);
  height: calc(100vh - 80px);
  @apply pf-object-cover pf-z-[100] pf-rounded-[30px] pf-top-[40px] pf-left-[40px] pf-absolute pf-h-[90%] pf-w-4/5
}

.slantDesign{
  rotate: -15deg
}


.preceedingDash::before{
  content:'-';
  @apply pf-relative pf-text-primary pf-top-11;
}

.beforeDash{
  @apply before:pf-content-['-'] before:pf-font-bold before:pf-text-[2.25rem] before:pf-relative before:pf-text-primary before:pf-mx-2 before:pf-top-[0.3rem] before:max-xl:pf-top-[0.5rem]
}

.pill{
  @apply pf-text-secondary-dark pf-bg-secondary pf-text-base pf-px-3 pf-py-[1px] pf-rounded-[20px] pf-font-semibold pf-uppercase
}

.topDownPageRenderTransisition{
  @apply pf-fixed pf-bg-secondary pf-animate-offCanvasSlideTToB pf-top-0 pf-left-0 pf-z-[1000] pf-w-screen pf-h-screen
}

.tooltip-btn {
  @apply pf-relative hover:pf-text-white hover:pf-bg-primary pf-transition-all pf-duration-200 pf-ease-in;
}

.tooltip-btn:hover::before {
  content: attr(data-tooltip);
  @apply pf-absolute pf-bottom-full pf-left-0 pf-p-2 pf-bg-primary pf-text-white pf-rounded-md pf-pointer-events-none pf-transition-opacity pf-duration-200 pf-ease-in-out pf-text-sm pf-mb-2 pf-opacity-100 pf-border-2 pf-border-white
}

input[type="text"], input[type="email"], textarea {
  @apply pf-border pf-border-secondary-dark0.6 pf-text-black pf-bg-white pf-font-normal pf-py-3 pf-px-7 pf-rounded-3xl focus-visible:!pf-border-primary focus:!pf-border-primary
} 

input::placeholder, textarea::placeholder {
  @apply pf-text-secondary-dark pf-bg-white pf-font-medium
} 

.error-state{
  @apply !pf-border-red-500
}


/*********************** BUTTONS CSS START ******************************/
.capsuleButton{
  transition: all 0.3s ease; /* Add a smooth transition effect */
  @apply pf-border-[1px] pf-border-solid pf-border-primary pf-rounded-[50px] pf-grid pf-grid-cols-12 pf-text-secondary-dark pf-relative pf-z-10 pf-bg-transparent pf-overflow-hidden;
}

.capsuleButton::after{
  content: "";
  transition: all 0.3s ease; /* Add a smooth transition effect */
  @apply pf-right-0 pf-absolute pf-top-0 pf-rounded-[50px] pf-w-0 pf-h-full
}
.capsuleButton:hover{
  @apply pf-text-white;
}

.capsuleButton:hover::after {
  @apply pf-w-full pf-bg-primary pf-z-[-1];
}
/*********************** BUTTONS CSS END ******************************/



/*********************** NAVBAR ICONS START ******************************/

.navText{
  @apply pf-absolute pf-top-0 pf-w-full pf-h-full pf-left-0 pf-bg-transparent pf-flex pf-justify-around pf-items-center pf-transition-all pf-ease-in pf-duration-200 pf-text-transparent pf-uppercase pf-text-sm pf-overflow-hidden pf-rounded-[50px] pf-tracking-widest hover:pf-left-[-100%] hover:pf-bg-primary hover:pf-w-[200%] hover:pf-text-white pf-font-semibold;
}

/*********************** NAVBAR ICONS END ******************************/


/*********************** ABOUT START ******************************/
.aboutExpNdEduListContainer{
  @apply pf-relative pf-py-0 pf-ps-[60px] pf-pe-5 pf-box-border pf-mb-[30px] after:pf-content-[''] after:pf-border-[1px] after:pf-border-solid after:pf-border-secondary-dark after:pf-border-spacing-60 after:pf-h-full after:pf-absolute after:pf-top-[10px] after:pf-left-[1.2rem] after:pf-opacity-20
}

.aboutExpNdEduListIcon{
  @apply pf-absolute pf-top-0 pf-left-0 pf-z-[1] pf-border pf-border-solid pf-border-primary pf-rounded-[50%] pf-p-1 pf-bg-primary pf-text-white pf-inline-flex pf-justify-center pf-items-center pf-w-10 pf-h-10 pf-text-xl
}

.workTextOverlay{
  @apply pf-absolute pf-w-full pf-h-full pf-bg-transparent pf-flex pf-justify-around pf-items-center pf-transition-all pf-ease-in pf-duration-200 pf-text-transparent pf-uppercase pf-text-sm pf-overflow-hidden pf-rounded-none pf-tracking-widest pf-text-center pf-bg-primary pf-text-white pf-font-semibold pf-cursor-pointer;
}

.animateTToB{
  animation: slideTToBKeyFrame 0.2s ease-in forwards;
}
.animateBToT{
  animation: slideBToTKeyFrame 0.2s ease-in forwards;
}
.animateLToR{
  animation: slideLToRKeyFrame 0.2s ease-in forwards;
}
.animateRToL{
  animation: slideRToLKeyFrame 0.2s ease-in forwards;
}

@keyframes slideTToBKeyFrame {
  0% {top: -100%;left: 0%;}
  100% {top: 0%;left: 0%;}
}

@keyframes slideBToTKeyFrame {
  0% {top: 100%;left: 0%;}
  100% {top: 0%;left: 0%;}
}

@keyframes slideLToRKeyFrame {
  0% {top: 0%;left: -100%;}
  100% {top: 0%;left: 0%;}
}

@keyframes slideRToLKeyFrame {
  0% {top: 0%;left: 100%;}
  100% {top: 0%;left: 0%;}
}
/*********************** ABOUT END ******************************/
